import { LoadingOutlined } from '@ant-design/icons';
import { GiveawayProduct } from '@omniafishing/core';
import { Button, Card, Form } from 'antd';
import _ from 'lodash';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { isPending, LoadingState } from '../../constants/loading_state';
import { useQueryString } from '../../hooks/use_query_string';
import { useUser } from '../../hooks/use_user';
import { useUserCampaignInfo } from '../../hooks/use_user_campaign_info';
import { apiV1 } from '../../lib/api';
import { WebAnalytics } from '../../lib/web_analytics';
import { AuthActions, hasAccessToken } from '../../redux/auth';
import { getSpecies } from '../../redux/reference_data';
import { RoutePaths } from '../../routes';
import {
  SignupGiveawayEnteredPageLocationState,
  SignupGiveawayEnteredWaterbodyData,
} from '../../routes/signup_giveaway_entered_page';
import utilities from '../../styles/utilities.less';
import { SignupGiveawayQueryParams } from './signup_giveaway';
import styles from './signup_giveaway.less';

interface SignupGiveawayFormProps {
  slug: string;
  giveawayProduct: GiveawayProduct;
}

export const SignupGiveawayForm = (props: SignupGiveawayFormProps) => {
  const { slug, giveawayProduct } = props;

  const dispatch = useDispatch();
  const location = useLocation<{ from?: string }>();
  const history = useHistory();
  const [form] = Form.useForm();
  const { user } = useUser();
  const isLoggedIn = useSelector(hasAccessToken);
  const [loadingState, setLoadingState] = useState(LoadingState.NOT_STARTED);
  const isSubmitting = isPending(loadingState);
  const allSpecies = useSelector(getSpecies);
  const { getCurrentQuery } = useQueryString();
  const currentQuery = getCurrentQuery<{
    [SignupGiveawayQueryParams.species]: string;
    [SignupGiveawayQueryParams.waterbody_name]: string;
    [SignupGiveawayQueryParams.waterbody_slug]: string;
    reset: string;
  }>();
  const resetAfterEntry = currentQuery.reset != null;
  const loggedInWithUser = isLoggedIn && user != null;
  const userCampaignInfo = useUserCampaignInfo(location.state?.from);
  const userSubmittedSpecies = allSpecies.find((s) => s.name === currentQuery.species);
  const userSubmittedWaterbody: SignupGiveawayEnteredWaterbodyData =
    currentQuery.waterbody_name != null && currentQuery.waterbody_slug != null
      ? {
          url_slug: currentQuery.waterbody_slug,
          primary_name: currentQuery.waterbody_name,
        }
      : null;

  const autoFollows = _.flatten([
    giveawayProduct.ambassadors.map((a) => a.full_name),
    giveawayProduct.brands.map((b) => b.display_name),
    giveawayProduct.waterbodies.map((w) => w.primary_name),
  ]);

  const hasAutoFollow = autoFollows.length > 0;

  const handleSubmit = () => {
    setLoadingState(LoadingState.PENDING);
    apiV1
      .giveawayProductEnter(slug, userCampaignInfo)
      .then((res) => {
        const locationState: SignupGiveawayEnteredPageLocationState = {
          giveawayProduct,
          userSubmittedSpecies,
          userSubmittedWaterbody,
        };
        history.push(RoutePaths.SIGNUP_GIVEAWAY_ENTERED, locationState);
        if (resetAfterEntry) {
          dispatch(AuthActions.AUTH_LOGOUT());
          const currentPage = location.pathname + location.search;
          setTimeout(() => {
            history.push(currentPage);
          }, 5000);
        }
      })
      .catch(() => {
        setLoadingState(LoadingState.ERROR);
      });

    const giveawayBrands = giveawayProduct.brands.map((b) => b.url_slug);

    if (giveawayBrands.length) {
      giveawayBrands.forEach((b: string) => {
        WebAnalytics.itemFavorited('brand', b);
      });
    }
  };

  return (
    <Card className={styles.form}>
      <h2 className={utilities.textCenter}>
        {loggedInWithUser
          ? `You're logged in and ready to enter the${'\u00A0'}giveaway`
          : 'Sign in or register to enter'}
      </h2>

      <Form onFinish={handleSubmit} form={form} layout="vertical">
        <Button
          type="primary"
          size="large"
          htmlType="submit"
          block
          style={{ marginBottom: 12 }}
          disabled={isSubmitting}
        >
          Enter Giveaway {isSubmitting ? <LoadingOutlined /> : ''}
        </Button>

        <p>
          By submitting, I accept the <Link to={RoutePaths.TERMS}>terms of use</Link> and contest
          rules listed blow.
        </p>
        {hasAutoFollow && (
          <p>* You will automatically follow {autoFollows.join(', ')} as a part of this entry</p>
        )}
      </Form>
    </Card>
  );
};
